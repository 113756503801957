import './libs/jquery.scroolly.js';
import './libs/scrolly/jquery.scrolly.js';
import './libs/jquery.imresize.js';
import './libs/jquery.accordion.js';


var mobileWidth = 576;
//var tabWidth = 768;
var tabWidth = 1200;

$('body').on('click', '.header__sidebar__nav-icon, .overlay--panel-active', function () {
    $('.header__sidebar').toggleClass('header__sidebar--hide');
    $('.overlay').toggleClass('overlay--panel-active');
});

$('body').on('click', '.orders__mass-edit__on, .orders__mass-edit__cansel, .orders__mass-edit__apply', function () {
    $('.orders__mass-edit').toggleClass('active')
});

$('body').on('click', '.orders-list__settings__link, .orders-list__settings .button', function () {
    $('.orders-list__settings .button, .orders-list__settings__checks').toggleClass('active');
});

$('body').on('click', '.order__add-bintranet-ticket__open, .order__add-bintranet-ticket .close, .order__add-bintranet-ticket__add', function () {
    $('.order__add-bintranet-ticket').toggleClass('active');
});


$('.header__line').scroolly([
    {
        to: 'con-top + 20px',
        css: {
            position: 'relative',
        }
    },
    {
        from: 'con-top + 20px',
        to: 'con-bottom - 100el = vp-top',
        class: 'header__line--move',
        css: {
            position: 'fixed',
        },
        onCheckIn: function(element){
            $('.header').css({overflow: 'hidden'});
        },
        onCheckOut: function(element){
            $('.header').css({overflow: 'initial'});
        }
    }
], $('body'));


// Показать фильтр
$('body').on('click', '.filter__show', function(){
    $('.filter').toggleClass('active');
});

function popups () {
    var $document = $(document),
        popover_on = '.list__item',
        items_slide_item_popover = popover_on + ' .item-popover';

    var close_popup = false;
    $document.on('mouseenter' , popover_on + ' .item-popover-move', function () {
        $document.find(items_slide_item_popover).removeAttr('style');
        $(this).find('.item-popover').css({display:'block'});
        clearTimeout(close_popup);
    });
    $document.on('mouseleave' , popover_on + ' .item-popover-move', function () {
        close_popup = setTimeout(function () {
            $document.find('.item-popover').removeAttr('style')
        }, 500)
    });
}
popups();

function moveToElem(elem) {
    $('html, body').animate({ scrollTop: $(elem).offset().top }, 300);
}
$('.header__menu ul a, .button--link').on('click', function () {
    if ($(this).data('target')) {
        moveToElem( '.'+$(this).data('target') );
        //$('.header__menu .icon__nav').trigger('click');
    }
});

function clickToPortfolio () {
    $('.portfolio__item a.button').css('visibility', 'hidden');
    $('.portfolio').on('click', '.portfolio__item.shuffle-item', function () {
        var href = $(this).find('a.button').attr('href');
        window.location = href;
    })
}
clickToPortfolio();

/* main-menu */
if (window.innerWidth <= tabWidth) {
    $('.minimodal .header__menu').accordIon({
        tabs_class : '> ul > li:has(ul) > .arrow',
        indicators_class: '> ul > li:has(ul) > .arrow', 
        callback: function () {
            setTimeout(function () {$('.minimodal').scrolly()}, 1300);
        }
    });
    $('.minimodal').attr('data-scrolly', "");
    //$('.minimodal').scrolly();
}

/* main-content */
/* show/hide more_button */
function showButtonMore (options) {
    var separator = options.separator || '<hr>';
    var hiddenBlockSelector = options.hidden_block_selector || '.content__hide-block';
    var moreLinkPattern = options.more_link_pattern || '<a href="javascript:" class="link more-link">подробнее</a>';
    var moreLinkShow = options.more_link_show || 'подробнее';
    var moreLinkHide = options.more_link_hide || 'скрыть';
    
    var hiddenBlock = $(hiddenBlockSelector);
    if (hiddenBlock.length) {
        if (typeof(separator) == 'number') {
            var hiddenBlockHtml = [];
            hiddenBlockHtml[0] = hiddenBlock.text().substr(0, separator);
            hiddenBlockHtml[1] = hiddenBlock.text().substr(separator, hiddenBlock.text().length);
        }
        else {
            var hiddenBlockHtml = hiddenBlock.html().split(separator);
        }
        
        if (hiddenBlockHtml.length > 1) {
            var hiddenBlockHtmlNew = '<p class="content__inner">' +
                                        '<span class="content__visible">' +
                                            hiddenBlockHtml[0] +
                                        '</span>' +
                                        '<span  class="content__points hidden">...</span>' + //hidden - значит блок будет скрыт
                                        '<span class="content__hidden hidden">' +
                                            hiddenBlockHtml[1] +
                                        '</span>' +
                                    '</p>' +
                                    moreLinkPattern.replace('{}', moreLinkShow);
                                    
            hiddenBlock.html(hiddenBlockHtmlNew);
        }
        
        hiddenBlock.on('click', '.show-more', showHideContent);
    }
    function showHideContent () {       
        $(this).parents(hiddenBlockSelector).find('.content__points').toggleClass('hidden-block'); 
        $(this).parents(hiddenBlockSelector).find('.content__hidden').fadeToggle('fast'); 
        
        $(this).toggleClass('show');
        if ($(this).html() == moreLinkShow) { 
            $(this).html(moreLinkHide) 
        } 
        else { 
            $(this).html(moreLinkShow) 
        } 
        return false;
    }
}
if (window.innerWidth <= mobileWidth) {
    showButtonMore({
        hidden_block_selector : '.main-content .content',
        more_link_pattern : '<div class="button button--gradient button--normal show-more">{}</div>',
        more_link_show : '<div class="button button__inner"></div>' +
                            '<div class="button button__inner--hover"></div><span>Подробнее</span>',
        more_link_hide : '<div class="button button__inner"></div>' +
                            '<div class="button button__inner--hover"></div><span>Скрыть</span>',
    });
}

/* services tab (user_view sidebar) */
function servicesTabs() {
    var mobileWidth = 600;
    
    function setHeight () {
        var tabs = $('.tabs');
        
        if ( window.innerWidth > mobileWidth ) {
            var padding = 50;
            var defaultHeight = tabs.height();
            
            var heightCurContent = $('.tabs__item__title.active + .tabs__item__content').height();
            tabs.css({'height' : heightCurContent + padding});
        }
        else {
            tabs.css({'height' : 'auto'});
            //$('html, body').animate({ scrollTop: $('.tabs__item__title.active').offset().top }, 300);
        }
    }
    
    setHeight();
    $(window).resize(setHeight);

    $('.tabs__item__title').on('click', function () {
        var checkTabSwitch = true;
        if ($(this).hasClass('active') && $(window).width() <= mobileWidth) checkTabSwitch = false;
            
        $('.tabs__item__title').removeClass('active');
        if (checkTabSwitch) $(this).addClass('active');
        setHeight();
        
        if (checkTabSwitch && $(window).width() < mobileWidth) $('html, body').animate({ scrollTop: $('.tabs__item__title.active').offset().top }, 300);
    });
}
if ( $('.tabs').length ) servicesTabs();


function miniModal (container_selector, method='slide', overflow=true) {
    var func = this;
    var container = $(container_selector);
    var button = container.find('.open-minimodal');
    var block = container.find('.minimodal');
    var overlay = $('<div>').addClass('overlay').css({
                display: 'block',
                background: 'transparent',
                zIndex: 2
            });
    if (container_selector == '.header__inner') {
        block.addClass('init');
        block.css('display', 'none');
    }
            
    this.closeMinimodal = function () {
        if (method == 'show') block.fadeOut();
        else block.slideUp();
        
        block.removeClass('open');
        container.removeClass('open');
        if (overflow) $('body').css('overflow', 'auto');
        
        overlay.remove();
    }
    
    button.on('click', function (event) {  
        event.preventDefault();
        if (block.hasClass('open')) {
            if (method == 'show') block.fadeOut();
            else block.slideUp();

            block.removeClass('open');
            container.removeClass('open');
            block.siblings('.overlay').remove();
            if (overflow) $('body').css('overflow', 'auto');
        }
        else {
            block.css('max-height', window.innerHeight - parseInt(block.css('top')))

            if (method == 'show') block.fadeIn();
            else block.slideDown();

            block.parent().append(overlay);
            if (overflow) $('body').css('overflow', 'hidden');
            if (window.innerWidth <= tabWidth) {
                //block.scrolly();
                block.scrolly('update', 0, 0.1);
            }

            overlay.on('click.close-minimodal', function () {
                func.closeMinimodal();
            });
            
            overlay[0].addEventListener('touchmove', function(e) {
                func.closeMinimodal();
            });    
            
            block.addClass('open');
            container.addClass('open');
        }
    });
}

if (window.innerWidth <= tabWidth) {
    let razdelMinimodal = new miniModal('.header__inner');
}

function serviceTable () {
    let nums_blocks = $('.order_nums');
    let check_blocks = $('.order_check');

    nums_blocks.on('click', '.order_nums__max', event => {
        let input = $(event.currentTarget).siblings('input');
        let val = input.val();
        input.val(parseInt(val) + 1);
    });
    nums_blocks.on('click', '.order_nums__min', event => {
        let input = $(event.currentTarget).siblings('input');
        let val = input.val();
        if (val > 1) input.val(parseInt(val) - 1);
    });


    check_blocks.on('change', 'input', event => {
        let tr = $(event.currentTarget).parents('tr')
        let is_title = !!tr.hasClass('title');

        let turn = tr.find('input').prop('checked');

        if (is_title) {
            let index = tr.index();
            let trs = tr.parents('tbody').find('tr');

            for (let i = index + 1; i < trs.length; i++) {
                if ($(trs[i]).hasClass('title')) break;

                $(trs[i]).find('input').prop('checked', turn);
            }
        }
    });
}
serviceTable();

function openTab (tabClass, hiddenClass) {
    
    var hidden = $(this).siblings('.'+hiddenClass);
    
    if (!hidden.length) return;
    if (!hidden.children().length) return;
    
    if ( $(this).hasClass(tabClass+'--open') ) {
        hidden.slideUp();
    }
    else {
        hidden.slideDown();
    }
    
    $(this).toggleClass(tabClass+'--open');
    
}

function setPlaceToForms () {
    var form = $('.callback-form');
    
    for (var i = 0; i < form.length; i++) {
        var place = '';
        
        if (form.eq(i).parents('.header__main').length) place = "Обратная связь - шапка сайта";
        else if (form.eq(i).parents('.footer').length) place = "Обратная связь - футер сайта";
        else if (form.eq(i).parents('.calc').length) place = "Обратная связь - калькулятор";
        
        form.eq(i).find('input[name="place"]').val(place);
    }
}
//setPlaceToForms();

function setPlaceToModalForm (elem) {
    if (elem.parents('.header__main').length) {
        place = "Обратная связь - шапка сайта";
        total = "";
    }
    else if (elem.parents('.footer').length) {
        place = "Обратная связь - футер сайта";
        total = "";
    }
    else if (elem.parents('.calc').length) {
        place = "Обратная связь - калькулятор";
        total = elem.parents('.calc').find('[name="contact_calc_total"]').val();
    }
    
    $('.modal#modal-callback').find('input[name="place"]').val(place);
    $('.modal#modal-callback').find('input[name="contact_calc_total"]').val(total);
}

/* modals */
modals();
function modals() {
    var body = $('body');
    var overlay = $('.overlay');
    var openModal = $('.open-modal');
    var modals = $('.modal');
    
    // show/hide
    openModal.on('click', function (event) {
        var id = $(this).data('modal-id');
        showModal(id, event);
        
        //setPlaceToModalForm($(this));
    });
    
    $('.modal__close').on('click', hideModal);
    overlay.on('click', function (event) {
        if (event.target == overlay[0]) {
            hideModal();
        }
    });
    
    //valid
    modals.find('form').on('submit', checkValid);
    modals.find('input, textarea').on('focus', function () {
        $(this).removeClass('input__error')
            .parents('.modal').find('.modal__error').removeClass('show');
    });
    
    function customFunc(id, event) {
        if (event && $(event.currentTarget).hasClass('service__order__button')) {
            let trs = $('.service__order tbody tr');
            let researches = [];

            [].forEach.call(trs, function (item, i) {
                if ($(item).find('.order_check input').prop('checked')) {
                    let point = ($(item).hasClass('title')) ? '> ' : '  - ';
                    let name = $(item).find('.order_name').text();
                    let count = $(item).find('.order_nums input').val();

                    researches.push(point+name+': '+count);
                }
            });

            $('#modal-callback form textarea[name="callback__research"]').val(researches.join('<br>'));
        }
    }
    
    function showModal(id, event) {
        $('#'+id).css({display: 'block'});
        overlay.fadeIn();
        if (!!$.fn.select2) {
            $('#'+id).find('select').select2({             
                minimumResultsForSearch: Infinity
            });
        }
        body.css({overflow: 'hidden'});

        customFunc(id, event);
    }
    
    function hideModal() {
        overlay.fadeOut();
        modals.fadeOut();
        body.css({overflow: 'auto'});
        [].forEach.call(modals.find('form'), function (item) {
            item.reset();
        })
    } 
    
    function checkValid (event) {
        var $this = $(this);
        var requiredFields = ['callback_phone'];
        var error = false;
        
        requiredFields.forEach(function (item, i) {
            var elem = $this.find('[name="'+item+'"]');
            if (!!elem.length) {
                if ( !elem.val() ) {
                    elem.addClass('input__error');
                    $this.find('.modal__error').addClass('show');
                    error = true;
                }
            }
        });
        
        if (error) {
            event.preventDefault(); 
            (event.cancelBubble) ? event.cancelBubble : event.stopPropagation;
        }
        
    }
}

